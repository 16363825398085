.directional-pad {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    ". up ."
    "left . right"
    ". down ."
  ;
  width: fit-content;
  margin: 0 auto;

  .arrow {
    --size: 2rem;
    height: var(--size);
    width: var(--size);
    font-size: var(--size);
  }

  .up {
    grid-area: up;
  }

  .down {
    grid-area: down;
    margin-top: -0.5rem;
  }

  .left {
    grid-area: left;
  }

  .right {
    grid-area: right;
  }
}
