@import "src/scss/colors";
@import "src/scss/config";

.forward-to-player-tracking {
	padding: 1rem;
	
	h3 {
		color: $primary;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 1rem;
		margin-top: 0.25rem;
	}
	
	input {
	
	}
	
	a {
		border: 2px solid $turquoise_topaz;
		color: $turquoise_topaz;
		padding: 0.5rem 0.8rem;
		background-color: transparent;
		border-radius: 0.25em;
		cursor: pointer;
		margin-left: 0.5em;
		text-decoration: none;
	}
}
