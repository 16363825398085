.sideNav {
  list-style: none;
  padding-left: 20px;
}

.sideNav .sideNav {
  list-style: upper-latin;
}

.sideNav .sideNav .sideNav {
  list-style: upper-alpha;
}

.sideNav .navPoint {
  padding: 6px 15px;
}
// li {
//   font-weight: initial;
// }
li.active > .label{
  font-weight: bolder;
}