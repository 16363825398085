.unsuportedBrowser {
  color: lightgray;
}
.browserLogo{
  max-height: 200px;
  height: 20vh;
}
.SWLogo{
  margin-top: 5vh;
  max-height: 100%;
  max-width: 30vw;
}
.headText{
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.downloadButtonRow{
  margin-top: 15vh;
}
.grant{
  color: rgb(45,45,57);
}
