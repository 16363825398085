

.mbsc-btn.tool{
  /* margin: 20px; */
  padding: 5px;
  border: 1px solid grey;
}
.mbsc-btn.tool.selected{
  border: 2px dashed black;
}

.mbsc-btn.action{
  /* margin: 20px; */
  padding: 5px;
  border: 1px solid grey;
}

.editorNotice {
  position: fixed;
  top: 10vh;
  right: 5vw;
  color: red; 
}

.redDot {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background-color: rgba(255,0,0,0.9);
  opacity: 80;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite alternate ;
  -webkit-animation: blink-animation 1s steps(5, start) infinite alternate ;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

