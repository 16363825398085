.clubSelect {
  input.clubFilter{
    padding-bottom: 0;
    margin-bottom:0;
    width: 50%;
    padding: 5px;
    position:relative;
    top: 1px;
  }
  select {
    width: 100%;
  }
  option {
    // text-align: center;
    padding: 5px 10px;

  }
}

.clickable {
  cursor: pointer;
}