.calibration-controls {
	.point-controller {
		display: grid;
		grid-template-columns: auto auto;
		align-items: center;
		
		&.distance-input {
			grid-template-columns: auto;
		}
		
		&.needs-value {
			/* border: 2px solid red; */
			/* outline: 2px solid red; */
			/* box-shadow: 0 0 2pt 2pt red; */
		}
		
		& > div {
			justify-self: end;
			width: fit-content;
		}
		
		.point-label {
			margin-left: 1em;
		}
		
		.point-label.required, &.distance-input.required .mbsc-label {
			&::after {
				content: '*';
				color: red;
				margin-left: 0.3em;
			}
		}
	}
	
	.check {
		color: green;
		margin-left: 0.3em;
	}

	.buttons {
        padding-left: 1em;
        padding-right: 1em;
    }
		
}
