#ProgressBarPlugins {
	position: relative;
	//bottom: 100%;
	height: 100%;
	width: 100%;
	background-color: darkgrey;
	
	div {
		display: inline-block;
		position: absolute;
		
		&:hover {
			height: 400%;
			bottom: 0;
			cursor: pointer;
		}
	}
}
