// .coachingtool.theme-a.dark .editorSite .outerContent .VideoContainer .video-player .children .sidebarHeader.inline{
//     display: inherit;
//     // position: inherit;
//     width: auto;
// }
// .coachingtool.theme-a.dark .editorSite .outerContent .VideoContainer .video-player .children .sidebarHeader.inline .menuButton{
//     position: fixed;
//     top: 10vh;
//     left: 10vw;
//     z-index: 80;
// }
.gateOpener.isOpen .greyOutArea{
  color: transparent;
  display: inherit;
  background-color: rgba(0,0,0,0.4);
  width: 10vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -1;
}

.greyOutArea {
  display: none
}

.userCenter{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.subButton{
  margin : auto;
  margin-top: 50px;
  font-size: small;
}