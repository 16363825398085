.ScreenRecorder {
  position: fixed;
  z-index: 999999;
  top: 40px;
  left: 25%;
  background-color: rgba(0,0,0,0.4);
  padding: 10px
}
.ScreenRecorder .RecorderOptions {
  color: white;
}