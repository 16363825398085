.fas.fas-button{
  margin: 5px;
}

.annotationItem {
  .mbsc-ic-material-close {
    // Make hitbox larger
    padding: 5px;
  }
}
