.BigPlayButtonContainer {
  position: relative;
  // top: 25%;
  // left: 47%;
  top: 0;
  left:0;
  width: 100%;
  height: calc(100% - 60px);
  cursor: pointer;
}
.BigPlayButtonWrapper {
  margin: auto;
  padding: 20px;
  font-size: 4em;
  text-align: center;
  position: relative;
  top: 20%
}

.BigPlayButton {
  padding-left: 20px;
  padding-right: 11px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 15px;
  color: white;
}