.tracked-positions-visualizer {
	li {
		&:before {
			border-top: 1px solid #ccc;
		}
	}
	.mbsc-ios .mbsc-lv-ic-m {
		color: white;
	}
}
