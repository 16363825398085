// .contextButton {
//   position: absolute;
//   top: 9vh;
//   z-index: 1;
// }
// .contextButton.left{
//   left: 24vw;
// }
// .contextButton.right{
//   right: 10vw;
// }
// .contextButton.lower{
//   top: 12vw;
// }

// .ContextButtonWrapper {
//     position: relative;
//     height: 100vh
// }
// .ContextButtonPositioner{
//     position: absolute;
//     bottom: 10vh;
//     left: 80vw;
// }

.ContextButtonsContainer {
  max-width: calc(100% - 400px);
  margin-left: auto;
  text-align: center;
}
@media(max-width: 768px) {
  .ContextButtonsContainer {
    max-width: 100%;
  }
}