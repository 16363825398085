.codeBox {
    font-size: 16px;
    border-radius: 4px;
    margin: 0 5px;
}
.codeBoxLabel {
    font-size: 16px;
}
.codeBoxButton {

}