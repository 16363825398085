.clubLogo{
  width: auto;
  height: 100%;
}
.LabelList .labelTag.clubLogoContainer{
  display: inline-flex;
  width: max-content;
  height: calc(1em + 1em);
  margin-right: 0.5em;
}

.LabelList .labelTag  .labelTag.noClick{
  cursor: default;
}