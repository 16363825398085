.languageOptions {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  .userCenterButton {
    text-decoration: none;
  }
}

.btn {
  font-family: 'Nekst';
  font-weight: 500;
}

.IOS_WARNING{
  color:orangered;
  border: 4px solid red;
  padding: 0 10px;
  border-radius: 6px;
}

.Subscription {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  text-align: center;
  justify-content: center;
  border-radius: 0.25em;
  text-decoration: none;
  color: black;

  &.col-xs {
    &.own {
      border: 2px dashed rgba(45, 152, 218, 1);
      transition: 0.2s ease-in-out;
      &:hover {
        border: 2px dashed rgba(45, 152, 218, 0.5);
      }
    }
    background-color: #f8f8f8;
    margin: 1em;
    max-width: calc(100% - 2em);
    .clubLogo {
      width: 150px;
      height: 150px;
      margin-bottom: 1em;
    }
    .clubLogo.mbsc-ic {
      margin-bottom: auto;
      font-size: 100px;
      color: rgba(45, 152, 218, 1);
    }
  }

  &.filler {
    min-width: 234.67px;
    background-color: transparent;
    border: none;
    &:hover {
      border: none;
    }
  }

  .clubName {
    margin-bottom: 0.5em;
  }

  .addBtn {
    margin-right: 0;
    cursor: unset;
    &:hover {
      color: rgba(32, 191, 107, 1);
    }
  }
}

.SubscriptionEdit {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  text-align: center;
  justify-content: center;
  border-radius: 0.25em;
  text-decoration: none;
  color: black;

  &.col-xs {
    background-color: #f8f8f8;
    margin: 1em;
    max-width: calc(100% - 2em);
    border: 2px dashed rgba(45, 152, 218, 1);
    transition: 0.2s ease-in-out;
    &:hover {
      border: 2px dashed rgba(45, 152, 218, 0.5);
    }
  }

  &.filler {
    min-width: 234.67px;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

#Languages .absolute {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 90;
}

.userSite {
  .DataView .image.clubLogo {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
  }

  .DataView.padded .image.clubLogo {
    padding: 30px 0px;
  }

  .formArea {
    // background-color: #fcfcfc;
    // padding: 10px;
  }

  .inputBar {
    border-radius: 0.25rem;
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    margin-bottom: 1em;

    .input {
      padding: 0.8rem 1rem;
      border: 0;
      font-size: 16px;
    }
  }
}

.center {
  text-align: center;
}

.noCenter {
  text-align: initial;
}

.centerBox {
  text-align: center;
  margin-top: 25vh;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  text-align: center;
  .filterInput {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    padding: 0.8rem 1rem;
    border: 0;
    font-size: 16px;
    width: calc(100% - 2rem);
    margin: 1rem 0rem;
    margin-top: 0;
    border-radius: 0.25rem;
  }
}

.modalContent.autocomplete {
  width: 40vw;
}

.modalContent {
  width: 75vw;
  max-height: 90vh;
  height: max-content;
  top: 2em;
  position: relative;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  padding-top: 2em;
  padding-bottom: 2em;

  video {
    max-width: 100%;
    max-height: 100%;
  }

  .btn_fluid {
    width: 100%;
  }
  .Collapsible {
    background-color: transparent;
    margin-top: 1em;
    margin-bottom: 1em;
    .suggest {
      text-align: left;
    }
    .btn {
      &.label {
        cursor: pointer;
      }
    }
  }
  .callToAction {
    text-align: left;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
}

.modal {
  .helpModal {
    height: 100%;
    max-width: 56.25vw;
    width: 100%;
    margin: 0 auto;
    top: 3em;
    position: relative;
    z-index: 9999;
    @media (max-width: 990px) {
      max-width: 90vw;
    }
    .video-container {
      position: relative;
      padding-bottom: 54%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.OptOutBanner {
  background-color: #FF3517;
  z-index: 99;
  position: fixed;
  bottom:0;
  width: 100vw;
  height: 15vh;
  min-height: 150px;
}
.OptOutBanner .OptOutText {
  padding: 10px 10vw;
}
.OptOutBanner .fakeLink {
  color: #151315;
  text-decoration: underline;
}

.date .mbsc-input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 5px;
}

@media(max-width: 768px) {
  .OptOutBanner {
    padding-bottom: 40px;
  }
}
@media(max-width: 400px) {
  .OptOutBanner {
    padding-bottom: 100px;
  }
}