.loginContainer {
    background-color: #232F34;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.loginWindow{
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
    padding-top: 20px;
    margin: auto;
    display: block;
}
span.rightUp {
    float: right;
    position: relative;
    bottom: 1em;
    margin-right: 1em;
    color: #1D1D25;
    cursor: pointer;
}
.refHead {
    position: relative;
    top: 20vh;
    text-align: center;
    color: white;
}
.loginText {
    margin-top: 80px;
    color: white;
}