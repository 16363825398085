// .sequenceItem{
//   border: 2px solid black;
//   margin-bottom: 5px;
// }

// .sequenceItem .label{
//   background-color: rgba(0,0,255,0.1);
//   margin: 0;
// }

// .sequenceItem .annotation{
//   background-color: rgba(0,255,0, 0.1);
//   margin: 0;
// }

.coachingtool.theme-a.dark .sequenceItem .sequenceItemContent .basicData .sequenceData .minuteLabel.dragHandle{
    // border-right: thick green groove;
    cursor: ns-resize;
} 
.draggableHeader{
    display: flex; 
    align-items: center;
    cursor: ns-resize;
}

.btn.matchLink {
    position: absolute;
    right: 30px;
}