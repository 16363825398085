.shortcut-info-popup {
	kbd {
		display: inline-block;
		padding: 3px 5px;
		font-size: 11px;
		line-height: 10px;
		color: #444d56;
		vertical-align: middle;
		background-color: #fafbfc;
		border: solid 1px #c6cbd1;
		border-bottom-color: #959da5;
		border-radius: 3px;
		box-shadow: inset 0 -1px 0 #959da5;
	}
	
	.striped {
		border-spacing: 0;
		
		tr {
			td {
				padding: 2px;
			}
			
			&:nth-child(even) {
				background-color: #D2D2D2;
			}
		}
	}
}
