//BASE
$white: #FFFFFE;
$white_dirty: #D3D9DA;
$baby_powder: #D3D9DA;
$white_gray: #D3D9DA;

//PRIMARY
$gunmetal: #151315;
$raisin_black: #151315;

// Special
$red: #D43235;
$yellowA: #FCCF18;
$blue: #FF3517;
$orange: #E78D23;
$green: #8EBF2E;

//SUCCESS
$algal_fuel:#FF3517;
$reptile_green:#FF3517;

//WARNING
$nyc_taxi: #151315;
$flirtatious: #151315;

//INFO
$turquoise_topaz:#FF3517;
$maximum_blue_green:#FF3517;

//DANGER
$desire: #FF3517;
$fusion_red: #FF3517;

//LIGHT
$innuendo: rgba(165, 177, 194, 1.0);
$twinkle_blue: rgba(209, 216, 224, 1.0);

//LINK
$boyzone: #FF3517;