.MarkerBar {
	position: relative;
	height: 0;
}

.MarkerContainer {
	position: absolute;
	bottom: 15px;
}

.Marker {
	position: relative;
	height: 40px;
	width: 25px;
	right: 12.5px;
	mask-repeat: no-repeat;
	mask-size: contain
}
