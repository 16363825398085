#ActionsContainer {
  display: flex;
  position: fixed;
  right: 1em;
  top: 40px;
  z-index: 50;
  .Actions {
    display: flex;
  }
}
#ActionsContainer .Actions .action span {
  font-size: 18px;
  // color: red;
  // text-align: center;
  // padding: 0.5em;
  // background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 572px) {
  #QuickbarContainer .Toolslist {
    font-size: 12px !important; 
  }
  #QuickbarContainer .Tool {
    min-width: 2em !important;
    padding: 1em 0 !important; 
  }
}