.line-selector {
	.control-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		
		.contextButton {
			margin: 1em;
		}
	}
	
	li.mbsc-lv-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
        .left {
            display: flex;
            flex-direction: row;

            .color-preview {
                width: 2em;
                margin-right: 1em;
            }
        }
			

		.contextButton {
			margin-bottom: 0;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
