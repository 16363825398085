.colorButton i {
  z-index: 10;
  color: white;
}
.mobiElement {
  z-index: 1;
  visibility: hidden;
  position: relative;
  right: 50px;
}

.forceFixedPosition{
  position: fixed !important;
}